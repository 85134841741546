
// TODO: Get all the URLs/alias from Strapi and use it here
export const URLS = {
    LATEST_NEWS: {
      name: 'News',
      alias: '/latest-property-news',
    },
    AREA_GUIDE_LANDING: {
      name: 'Areas We Cover',
      alias: '/about-wards-of-kent/area-guide/',
    },
    ABOUT: {
      name: 'About',
      alias: '/about-wards-of-kent/',
    },
    TESTIMONIALS: {
      name: 'Testimonials',
      alias: '/about-wards-of-kent/testimonials/',
    },
    CASE_STUDIES: {
      name: 'Case Studies',
      alias: '/about-wards-of-kent/case-studies/',
    },
    VALUATION: {
      name: 'Property Valuation',
      alias: '/sell-your-property/property-valuation/',
    },
    FIND_A_PROPERTY: {
      name: 'Find a Property',
      alias: '/property/for-sale/in-kent',
    },
    FIND_A_PROPERTY_RENT: {
      name: 'Find a Property',
      alias: '/property/to-rent/in-kent',
    },
    NEW_HOMES_SEARCH: {
      name: 'Find a Property',
      alias: '/property/new-homes/for-sale',
    },
    CONTACT: {
      name: 'Find a Property',
      alias: '/get-in-touch/',
    },
    BRANCHES: {
      name: 'Find your local branch',
      alias: '/branchfinder',
    },
    PROPERTY_MANAGEMENT: {
      name: 'Property Management',
      alias: '/services/shared-property-ownership/',
    },
    MORTGAGES: {
      name: 'Mortgages',
      alias: '/buy-property/how-much-mortgage-can-you-afford/',
    },
    SERVICES: {
      name: 'Services',
      alias: '/services/',
    },
    YIELD_CALCULATOR: {
      name: 'Rental yield calculator',
      alias: '/landlords/buy-to-let-investors/increasing-your-yields/property-yield-calculator/',
    },
    GIVING_BACK: {
      name: 'Giving Back',
      alias: '/about-wards-of-kent/giving-back/',
    },
    BUY_TO_LETS: {
      name: 'Buy to Lets',
      alias: '/property/for-sale/in-kent/with-buy-to-let-opportunity',
    }
}


export const LATEST_NEWS_PAGE_URL = URLS.LATEST_NEWS;
export const AREA_GUIDE_PAGE_URL = URLS.AREA_GUIDE_LANDING;
export const ABOUT_PAGE_URL = URLS.ABOUT;
export const CASE_STUDIES_PAGE_URL = URLS.CASE_STUDIES;
export const VALUATION_PAGE_URL = URLS.VALUATION;
export const FIND_A_PROPERTY_PAGE_URL = URLS.FIND_A_PROPERTY;
export const FIND_A_PROPERTY_RENT_PAGE_URL = URLS.FIND_A_PROPERTY_RENT;
export const NEW_HOMES_SEARCH_PAGE_URL = URLS.NEW_HOMES_SEARCH;
export const CONTACT_PAGE_URL = URLS.CONTACT;
export const BRANCHES_PAGE_URL = URLS.BRANCHES;
export const TESTIMONIALS_PAGE_URL = URLS.TESTIMONIALS;
export const PROPERTY_MANAGEMENT_PAGE_URL = URLS.PROPERTY_MANAGEMENT;
export const MORTGAGES_PAGE_URL = URLS.MORTGAGES;
export const SERVICES_PAGE_URL = URLS.SERVICES;
export const YIELD_CALCULATOR_PAGE_URL = URLS.YIELD_CALCULATOR;
export const GIVING_BACK_PAGE_URL = URLS.GIVING_BACK;
export const BUY_TO_LETS_URL = URLS.BUY_TO_LETS;