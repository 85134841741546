import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery'

import { VALUATION_PAGE_URL, BRANCHES_PAGE_URL, ABOUT_PAGE_URL, PROPERTY_SERVICES_PAGE_URL, AREA_GUIDE_PAGE_URL, LATEST_NEWS_PAGE_URL, PROPERTY_MANAGEMENT_PAGE_URL, TESTIMONIALS_PAGE_URL, MORTGAGES_PAGE_URL, SERVICES_PAGE_URL, YIELD_CALCULATOR_PAGE_URL } from '../../common/site/constants'

const PopularSearchOffice = (props) => {

    var URL = '/property/for-sale/'
    var NEW_HOME_URL = '/property/new-homes/for-sale/'
    
    var RENT_URL = '/property/to-rent/'

  return (
    <Row>
      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${URL}in-${props.url}`}>Property for sale in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${props.url}/type-apartment`}>Apartments for sale in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${props.url}/type-flats`}>Flats for sale in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${URL}in-${props.url}/type-bungalows`}>Bungalows for sale in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${NEW_HOME_URL}in-${props.url}`}>New homes for sale in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${SERVICES_PAGE_URL.alias}`}>Property services in {props.area}</a></li>

        </ul>
      </Col>
      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${RENT_URL}in-${props.url}`}>Property to rent in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${RENT_URL}in-${props.url}/type-apartment`}>Apartments to rent in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${RENT_URL}in-${props.url}/type-flats`}>Flats to rent in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${RENT_URL}in-${props.url}/type-bungalows`}>Bungalows to rent in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${YIELD_CALCULATOR_PAGE_URL.alias}`}>Rental yield calculator</a></li>
          <li><a className="footer_scroll_top" href={`${TESTIMONIALS_PAGE_URL.alias}`}>Testimonials</a></li>

        </ul>
      </Col>

      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${ABOUT_PAGE_URL.alias}`}>About Wards of Kent estate agents</a></li>
          <li><a className="footer_scroll_top" href={`${BRANCHES_PAGE_URL.alias}`}>Estate agents in Maidstone</a></li>
          <li><a className="footer_scroll_top" href={`${BRANCHES_PAGE_URL.alias}`}>Estate agents in Gillingham</a></li>
          <li><a className="footer_scroll_top" href={`${BRANCHES_PAGE_URL.alias}`}>Estate agents in Dartford</a></li>
          <li><a className="footer_scroll_top" href={`${BRANCHES_PAGE_URL.alias}`}>Estate agents in Ashford</a></li>
          <li><a className="footer_scroll_top" href={`${BRANCHES_PAGE_URL.alias}`}>Estate agents in Canterbury</a></li>

        </ul>
      </Col>

      <Col md={6} lg={3} className="properties-info">
        <ul>
          <li><a className="footer_scroll_top" href={`${VALUATION_PAGE_URL.alias}`}>Property valuation in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${PROPERTY_MANAGEMENT_PAGE_URL.alias}`}>Property management in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${MORTGAGES_PAGE_URL.alias}`}>Mortgage brokers in {props.area}</a></li>
          <li><a className="footer_scroll_top" href={`${SERVICES_PAGE_URL.alias}`}>Property services</a></li>
          <li><a className="footer_scroll_top" href={`${ABOUT_PAGE_URL.alias}`}>About Wards of Kent estate agents</a></li>
          <li><a className="footer_scroll_top" href={`${LATEST_NEWS_PAGE_URL.alias}`}>Latest Property News</a></li>

        </ul>
      </Col>
    </Row>
  )

}

export default PopularSearchOffice;